import {
    BankAccountDto,
    InvoiceDetailsDto,
    InvoiceSummaryDto
} from "../domain/invoice";
import { getUserInfo } from "../utils/oidcUtil";
import { QueryFilter, SearchResultDto, claimsInvoicingApi } from "./claimsInvoicingApi";
import { HierarchicalDto } from "./usersApi";

export const invoiceApi = claimsInvoicingApi.injectEndpoints({
    endpoints: builder => ({
        getInvoices: builder.query<SearchResultDto<InvoiceDto>, QueryFilter>({
            query: params => ({
                params,
                url: `/invoices`
            })
        }),
        getInvoice: builder.query<InvoiceDetailsDto, string>({
            query: invoiceId => ({
                url: `/invoices/${invoiceId}`
            })
        }),
        getInvoiceSummary: builder.query<InvoiceSummaryDto, string>({
            query: invoiceId => ({
                url: `/invoices/${invoiceId}/summary`
            })
        }),
        createInvoiceByPost: builder.mutation<InvoiceSummaryDto, {
            invoiceQueryDto: InvoiceQueryDto, companyId?: string
        }>({
            query: query => ({
                url: `/invoices` + (query.companyId ? `/${query.companyId}` : ""),
                method: "POST",
                body: query.invoiceQueryDto
            })
        }),
        updateInvoiceByPut: builder.mutation<
            string,
            { id: string; invoice: InvoiceDetailsDto }
        >({
            query: ({ id, invoice }) => ({
                url: `/invoices/${id}`,
                method: "PUT",
                body: invoice
            })
        }),
        deleteInvoice: builder.mutation<string, string>({
            query: id => ({
                url: `/invoices/${id}`,
                method: "DELETE"
            })
        }),
        postBankAccount: builder.mutation<BankAccountDto, { id: string, bankAccount: BankAccountDto }>({
            query: ({ id, bankAccount }) => ({
                url: `/invoices/${id}/bankAccounts`,
                method: "POST",
                body: bankAccount
            })
        }),
        uploadAttachmentByPost: builder.mutation<string, { id: string; form: FormData }>(
            {
                query: ({ id, form }) => ({
                    url: `/invoices/${id}/attachments`,
                    method: "POST",
                    body: form,
                    responseHandler: "text"
                })
            }
        ),
        submitInvoiceByPost: builder.mutation<void, { id: string, invoiceDetails: InvoiceDetailsDto }>({
            query: ({ id, invoiceDetails }) => ({
                url: `/invoices/${id}/submit`,
                method: "POST",
                body: invoiceDetails
            })
        })
    })
});

export const {
    useLazyGetInvoicesQuery,
    useGetInvoiceQuery,
    useGetInvoiceSummaryQuery,
    useCreateInvoiceByPostMutation,
    useUpdateInvoiceByPutMutation,
    useDeleteInvoiceMutation,
    usePostBankAccountMutation,
    useUploadAttachmentByPostMutation,
    useSubmitInvoiceByPostMutation
} = invoiceApi;

// This method is using fetch instead of Redux Toolkit Query API
// to ensure that the file is not stored in the browser cache.
export const getAttachment = (invoiceId: string, docId: string | undefined) => {
    fetch(
        `${
            import.meta.env.VITE_API_BASE_URL
        }/cia/api/v1/invoices/${invoiceId}/attachments/${docId}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getUserInfo()?.access_token
            }
        }
    )
        .then((res) => res.json())
        .then(async(documentDto: DocumentDto) => {
            const fileblob = b64toBlob(documentDto.content, documentDto.fileExtension);
            const url = window.URL.createObjectURL(new Blob([fileblob]));

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${documentDto.filename}.${documentDto.fileExtension}`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode?.removeChild(link);
        });
};

// This method is using fetch instead of Redux Toolkit Query API
// to ensure that the file is not stored in the browser cache.
export const getExportedInvoices = (invoiceIds?: string[], queryFilter?: QueryFilter) => {
    let url = `${
        import.meta.env.VITE_API_BASE_URL
    }/cia/api/v1/invoices/export`;
    const params = new URLSearchParams();

    if (invoiceIds) {
        invoiceIds.forEach(id => params.append("invoiceIds", id));
    }

    if (queryFilter) {
        Object.entries(queryFilter).forEach(([key, value]) => {
            params.append(key, String(value));
        });
    }

    if (params.toString()) {
        url += "?" + params.toString();
    }

    fetch(
        url,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getUserInfo()?.access_token
            }
        }
    )
        .then(res => res.json())
        .then(async(serviceResult: ServiceResult) => {
            const documentDto = serviceResult.resultData;
            const fileblob = b64toBlob(documentDto.content, documentDto.fileExtension);
            const fileurl = window.URL.createObjectURL(new Blob([fileblob]));

            const link = document.createElement("a");
            link.href = fileurl;
            link.setAttribute("download", `${documentDto.filename}.${documentDto.fileExtension}`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode?.removeChild(link);
        });
};

export type InvoiceDto = {
    id: string;
    invoiceDate: string;
    invoiceDueDate: string;
    invoiceReference: string;
    subClaimsRef: string;
    incidentDate: string;
    vessel: string;
    status: string;
    submittedDate?: string;
    totalInvoiceAmount: string;
    rejectedInvoiceMessage: string;
    hierarchy: HierarchicalDto;
};

export type ServiceResult = {
    description: string;
    resultCode: string;
    resultData: DocumentDto;
}

export type InvoiceQueryDto = {
    claimId: string;
    subClaimId: string;
};

export type DocumentDto = {
    content: string;
    filename: string;
    fileExtension: string;
};

const SLICE_SIZE = 512;

const b64toBlob = (b64Data: string, contentType = "", sliceSize = SLICE_SIZE) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers: Uint8Array = Uint8Array.from({ length: slice.length });
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
};
