import { MemberDetailsDto } from "../domain/invoice";
import { QueryFilter, SearchResultDto, claimsInvoicingApi } from "./claimsInvoicingApi";

export const claimsApi = claimsInvoicingApi.injectEndpoints({
    endpoints: builder => ({
        getClaimsByCompanyId: builder.query<
            SearchResultDto<ClaimsItemDto>,
            ClaimParams
        >({
            query: ({ id = "", params }) => ({
                params,
                url: `/claims/company/${id}`
            })
        }),
        getClaimsInfoById: builder.query<ClaimsDetailsDto, string>({
            query: claimsId => ({
                url: `/claims/${claimsId}`
            })
        }),
        getSubClaimsInfoBySubClaim: builder.query<
            SubClaimsInfoDto,
            { claimId: string; subClaimId: string }
        >({
            query: ({ claimId, subClaimId }) => ({
                url: `/claims/${claimId}/subclaims/${subClaimId}`
            })
        }),
        getClaimsMember: builder.query<MemberDetailsDto, string>({
            query: claimsId => ({
                url: `/claims/${claimsId}/member`
            })
        })
    })
});

export const {
    useLazyGetClaimsByCompanyIdQuery,
    useGetClaimsInfoByIdQuery,
    useLazyGetClaimsInfoByIdQuery,
    useLazyGetSubClaimsInfoBySubClaimQuery,
    useGetClaimsMemberQuery
} = claimsApi;

export type ClaimParams = {
    id?: string,
    params: QueryFilter
}

export const DEFAULT_QUERY_FILTER: QueryFilter = {
    page: 1,
    limit: 10,
    sortDirection: "DESC",
    status: "Open"
};

export type ClaimsDetailsDto = {
    subClaims: SubClaimsDetailsDto[];
};

export type SubClaimsDetailsDto = {
    id: string;
    claimType: string;
    description: string;
    claimHandlerId: string;
    claimHandlerName: string;
    claimHandlerEmail: string;
    invoices: InvoiceElementDto[];
    gardSubClaimRef: string;
};

type InvoiceElementDto = {
    id: string;
    invoiceNumber: string;
    status: string;
    submittedDate: string;
};

export type SubClaimsInfoDto = {
    vatCompany: string;
    vatNo: number;
    coEntity: string;
    companyRelation: string;
    vesselName: string;
    clientName: string;
    subClaims: string[];
    bankAccounts: BankAccount[];
};

export type ClaimsItemDto = {
    id: string;
    status: string;
    incidentDate: string;
    description: string;
    invoiceCount: number;
    vesselName: string;
    clientName: string;
};

type BankAccount = {
    accountNo: string;
    currency: string;
};
