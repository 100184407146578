import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TableContainer, Icon, Flex, chakra, Text, IconButton } from "@chakra-ui/react";
import { CellContext, ColumnDef, OnChangeFn, PaginationState, SortingState, VisibilityState, getCoreRowModel, getExpandedRowModel, useReactTable } from "@tanstack/react-table";
import { FiChevronRight } from "react-icons/fi";
import { numericFormatter } from "react-number-format";
import { Pagination, InfoStrokeIcon } from "@digital-services-gard-as/anchor-chakra";

import { InvoiceDto } from "../../../api/invoiceApi";
import { TruncatedText } from "../../../components/TableText";
import { StatusBadge } from "../../../utils/invoiceUtils";
import { SearchResultDto } from "../../../api/claimsInvoicingApi";
import InvoicesTableRow from "./InvoicesTableRow";
import { useTranslation } from "react-i18next";
import { RejectedInvoiceModal } from "../modal/RejectedInvoiceModal";
import TableContent from "../../../components/TableContent";
import { useFeature } from "flagged";
import { useGetHierarchyQuery } from "../../../api/usersApi";

type InvoicesTableProps = {
    invoicesResult: SearchResultDto<InvoiceDto> | undefined,
    submittedInvoiceId: string | undefined,
    pageIndex: number,
    pageSize: number,
    setPagination: OnChangeFn<PaginationState>,
    sorting: SortingState,
    setSorting: OnChangeFn<SortingState>,
    isFetchingInvoices: boolean
}

export const InvoicesTable = React.memo(function InvoicesTable({
    invoicesResult,
    submittedInvoiceId,
    pageIndex,
    pageSize,
    setPagination,
    sorting,
    setSorting,
    isFetchingInvoices
}: InvoicesTableProps) {
    const { t } = useTranslation("claimsInvoicesOverview");
    const [rejectedModalOpen, setRejectedModalOpen] = useState(false);
    const [rejectedInvoiceMessage, setRejectedInvoiceMessage] = useState("");

    const isRejectedInvoiceInfoEnabled = useFeature("CIA-906-InvoiceRejectionMessage") as boolean;
    const isSortingInvoicesEnabled = useFeature("CIA-567-SortListOfInvoices") as boolean;
    const isRegisterInvoicesSubcompanyEnabled = useFeature("CIA-1097-RegisterInvoicesSubcompany") as boolean;
    const { data: branchCompanies } = useGetHierarchyQuery("");

    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        branchCompany: isRegisterInvoicesSubcompanyEnabled
            && (branchCompanies?.length ?? 0) > 0
    });

    useEffect(() => {
        setColumnVisibility({
            branchCompany: isRegisterInvoicesSubcompanyEnabled
                && (branchCompanies?.length ?? 0) > 0
        });
    }, [isRegisterInvoicesSubcompanyEnabled, branchCompanies]);

    const rejectedInfoButton = useCallback((e: React.MouseEvent<HTMLElement>, invoiceMessage: string) => {
        e.stopPropagation();
        setRejectedInvoiceMessage(invoiceMessage);
        setRejectedModalOpen(true);
    }, []);

    const tableText = (tableId: string) => ({ row }: CellContext<InvoiceDto, unknown>) => {
        if (tableId === "totalInvoiceAmount") {
            const valueCurrency = row.getValue(tableId) as string;
            const value = valueCurrency?.split(" ")[0];
            const currency = valueCurrency?.split(" ")[1];
            const valueFormatted = numericFormatter(value, {
                thousandSeparator: ",",
                decimalSeparator: ".",
                decimalScale: 2
            });
            return <TruncatedText text={valueFormatted + " " + currency} />;
        }

        return <TruncatedText text={row.getValue(tableId)} />;
    };

    const columns: ColumnDef<InvoiceDto>[] = [
        {
            header: t("invoicesTable.invoiceDate") as string,
            accessorKey: "invoiceDate",
            enableSorting: isSortingInvoicesEnabled,
            enableHiding: false
        },
        {
            header: t("invoicesTable.invoiceDueDate") as string,
            accessorKey: "invoiceDueDate",
            enableSorting: isSortingInvoicesEnabled,
            enableHiding: false
        },
        {
            header: t("invoicesTable.invoiceReference") as string,
            id: "invoiceReference",
            accessorKey: "invoiceReference",
            cell: tableText("invoiceReference"),
            enableSorting: isSortingInvoicesEnabled,
            enableHiding: false
        },
        {
            header: t("invoicesTable.subClaimsRef") as string,
            accessorKey: "subClaimsRef",
            cell: tableText("subClaimsRef"),
            enableSorting: isSortingInvoicesEnabled,
            enableHiding: false
        },
        {
            header: t("invoicesTable.incidentDate") as string,
            accessorKey: "incidentDate",
            cell: tableText("incidentDate"),
            enableSorting: isSortingInvoicesEnabled,
            enableHiding: false
        },
        {
            header: t("invoicesTable.vessel") as string,
            accessorKey: "vessel",
            cell: tableText("vessel"),
            enableSorting: isSortingInvoicesEnabled,
            enableHiding: false
        },
        {
            header: t("invoicesTable.branchCompany") as string,
            accessorFn: (row) => row.hierarchy?.companyName,
            accessorKey: "branchCompany",
            cell: tableText("branchCompany"),
            enableSorting: isSortingInvoicesEnabled,
            enableHiding: true
        },
        {
            header: t("invoicesTable.status") as string,
            accessorKey: "status",
            cell: ({ row }) =>
                <Flex h="fit-content" alignItems="center" gap={2}>
                    <Flex h="fit-content">
                        <StatusBadge
                            id={"statusBadge" + row.id}
                            invoiceStatus={row.getValue("status")}
                            submittedDate={row.original.submittedDate}
                        />
                    </Flex>
                    {row.getValue("status") === "Rejected" && isRejectedInvoiceInfoEnabled && (
                        <IconButton
                            data-testid="invoiceRejectedBtn"
                            backgroundColor="anchor.gray.100"
                            size="sm"
                            icon={<InfoStrokeIcon size="sm" color="anchor.navy.900" />}
                            aria-label={"Rejected invoice"}
                            onClick={(e) => rejectedInfoButton(e, row.original.rejectedInvoiceMessage)}
                        />
                    )}
                </Flex>,
            enableSorting: isSortingInvoicesEnabled,
            enableHiding: false
        },
        {
            header: t("invoicesTable.totalInvoiceAmount") as string,
            accessorKey: "totalInvoiceAmount",
            cell: tableText("totalInvoiceAmount"),
            enableSorting: false,
            enableHiding: false
        },
        {
            header: () => null,
            id: "navigation",
            cell: ({ row }) => (
                <Icon
                    id={"navigateInvoiceBtn" + row.id}
                    aria-label="Open invoice"
                    as={FiChevronRight}
                />
            ),
            size: 30,
            enableHiding: false
        }
    ];

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize
        }),
        [pageIndex, pageSize]
    );

    const handlePageChange = useCallback((index: number) => {
        setPagination({ pageIndex: index, pageSize });
    }, [setPagination, pageSize]);

    const handlePageSizeChange = useCallback((size: number) => {
        setPagination({ pageIndex: 1, pageSize: size });
    }, [setPagination]);

    const table = useReactTable<InvoiceDto>({
        columns,
        data: invoicesResult?.items ?? [],
        pageCount: invoicesResult?.totalResults,
        state: {
            pagination,
            sorting,
            columnVisibility
        },
        onPaginationChange: setPagination,
        manualPagination: true,
        manualSorting: true,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        enableSortingRemoval: false
    });

    const AnchorPagination = chakra(Pagination);

    return (
        <>
            <TableContainer
                data-testid="invoicesTable"
                overflowY="auto"
                border="2px solid"
                borderRadius="5px"
                borderColor={"anchor.brown.200"}
                maxH="60rem"
            >
                <TableContent table={table}>
                    {table.getRowModel().rows.map(row =>
                        <InvoicesTableRow key={row.original.id} row={row} submittedInvoiceId={submittedInvoiceId} />
                    )}
                </TableContent>
                { !invoicesResult?.items?.length && !isFetchingInvoices && (
                    <Text pt="1rem" data-testid="emptyInvoicesList">{t("emptyInvoicesList")}</Text>
                )}
                <AnchorPagination
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    page={pageIndex}
                    pageSize={pageSize}
                    returnedResults={invoicesResult?.returnedResults ?? 0}
                    totalResults={invoicesResult?.totalResults ?? 0}
                    alignItems="center"
                    position="sticky"
                    bottom={0}
                    zIndex={1}
                    bg="anchor.white"
                    maxW="100%"
                />
            </TableContainer>
            <RejectedInvoiceModal
                isOpen={rejectedModalOpen}
                onClose={() => setRejectedModalOpen(false)}
                rejectedInvoiceMessage={rejectedInvoiceMessage} />
        </>
    );
});
