import React from "react";
import { Alert, AlertDescription, AlertIcon, Badge, Box, Divider, Flex, Grid, GridItem, Link as ChakraLink, Icon, Spinner, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FiArrowRight } from "react-icons/fi";
import { Link as ReactRouterLink } from "react-router-dom";

import { useGetClaimsInfoByIdQuery } from "../../api/claimsApi";
import { ClaimsHandler } from "../../components/ClaimsHandler";
import { StatusBadge } from "../../utils/invoiceUtils";

type ClaimsDetailsProps = {
    claimsId: string
}

const ClaimsDetails = React.memo(function ClaimsDetails({ claimsId }: ClaimsDetailsProps) {
    const { t } = useTranslation(["claimsInvoicesOverview", "errorMessages"]);
    const {
        currentData: claimsInfo,
        isError: isClaimsInfoError,
        isFetching: isClaimsInfoFetching
    } = useGetClaimsInfoByIdQuery(claimsId);

    if (isClaimsInfoError) {
        return (
            <Flex backgroundColor="#FAFBFB" id="claimsDetailsError">
                <Alert
                    variant="error"
                    status="error"
                    minW="18rem"
                    maxW="40rem"
                    maxH="10rem"
                    margin="1rem"
                    id="noDataAlert"
                    overflowWrap="break-word"
                >
                    <AlertIcon />
                    <AlertDescription wordBreak="break-word">
                        {t("errorMessages:claimsInvoicesOverview.cannotRetrieveSubclaim")}
                    </AlertDescription>
                </Alert>
            </Flex>
        );
    }

    if (isClaimsInfoFetching) {
        return (
            <Flex justifyContent="center" m="3rem 0">
                <Spinner label={t("claimsInvoicesOverview:claimsDetails.subclaimsFetching") as string} />
            </Flex>
        );
    }

    const getInvoiceNumber = (invoiceNumber: string | undefined) =>
        `${t("claimsInvoicesOverview:claimsDetails.invoice") ?? ""} ${invoiceNumber ?? ""} `;

    return (
        <Box backgroundColor="anchor.brown.100" id="claimsDetails">
            <Grid templateColumns="repeat(5, 1fr)" rowGap="2rem" columnGap="5rem" p="2rem 3rem" maxW="100%">
                { claimsInfo?.subClaims.map((subClaim, subClaimIndex) => (
                    <>
                        <GridItem maxW="25rem">
                            <VStack spacing="0.5rem" align="baseline">
                                <Text data-testid={"subClaimId" + subClaimIndex}>
                                    {t("claimsInvoicesOverview:claimsDetails.subClaim")} {subClaim.gardSubClaimRef}
                                </Text>
                                <Badge bg="#DACEE8">{subClaim.claimType}</Badge>
                            </VStack>
                        </GridItem>
                        <GridItem colSpan={2}>
                            { subClaim.invoices.length >= 1 ? (
                                subClaim.invoices.map((invoice, invoiceIndex) => (
                                    <VStack align="baseline" gap="1rem">
                                        {invoiceIndex === 0 && (
                                            <Text
                                                as="b"
                                                whiteSpace="pre-wrap"
                                                overflowWrap="break-word"
                                            >
                                                {subClaim.description}
                                            </Text>
                                        )}
                                        <ChakraLink
                                            color="#3379B9"
                                            as={ReactRouterLink}
                                            to={"/invoice/" + invoice.id}
                                        >
                                            <Flex alignItems="center" gap="0.2rem">
                                                <Text>
                                                    {getInvoiceNumber(invoice.invoiceNumber)}
                                                </Text>
                                                <Icon as={FiArrowRight} />
                                            </Flex>
                                        </ChakraLink>
                                        <StatusBadge
                                            id={"statusBadge" + subClaimIndex}
                                            invoiceStatus={invoice.status}
                                            submittedDate={invoice.submittedDate}
                                        />
                                        {(
                                            subClaim.invoices.length > 1
                                            && invoiceIndex !== subClaim.invoices.length - 1
                                        ) && (
                                            <Divider />
                                        )}
                                    </VStack>
                                ))
                            ) : (
                                <VStack align="baseline">
                                    <Text as="b" whiteSpace="pre-wrap" overflowWrap="break-word">
                                        {subClaim.description}
                                    </Text>
                                    <Text>{t("claimsInvoicesOverview:claimsDetails.noInvoices")}</Text>
                                </VStack>
                            )}
                        </GridItem>
                        <GridItem>
                            <ClaimsHandler
                                claimHandlerName={subClaim.claimHandlerName}
                                claimHandlerEmail={subClaim.claimHandlerEmail}
                            />
                        </GridItem>
                        { (claimsInfo?.subClaims.length > 1 && subClaimIndex !== claimsInfo?.subClaims.length - 1) && (
                            <GridItem rowSpan={1} colSpan={5}>
                                <Divider />
                            </GridItem>
                        )}
                    </>
                ))}
            </Grid>
        </Box>
    );
});

export default ClaimsDetails;
