import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Stack,
    Tag,
    Text
} from "@chakra-ui/react";
import { HierarchicalDto } from "../../../api/usersApi";
import { ArrowRightIcon } from "@digital-services-gard-as/anchor-chakra";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { TruncatedText } from "../../../components/TableText";

type BranchCompanyModalProps = {
    isOpen: boolean;
    onClose: () => void;
    selectedBranch: HierarchicalDto | null;
    setSelectedBranch: (branch: HierarchicalDto) => void;
    branchCompanies: HierarchicalDto[];
    isLoading: boolean;
    isError: boolean;
};

export const BranchCompanyModal = ({
    isOpen,
    onClose,
    selectedBranch,
    setSelectedBranch,
    branchCompanies,
    isLoading,
    isError
}: BranchCompanyModalProps) => {
    const { t } = useTranslation(["claimsInvoicesOverview", "main"]);
    const handleSelectBranch = useCallback(
        (branch: HierarchicalDto) => {
            setSelectedBranch(branch);
            window.sessionStorage.setItem("selectedBranch", JSON.stringify(branch));
            onClose();
        },
        [onClose, setSelectedBranch]
    );
    return (
        <Modal isOpen={isOpen} onClose={onClose} >
            <ModalOverlay p="1rem" />
            <ModalContent p="1rem" data-testid="branchCompanyModal">
                <ModalHeader textAlign="left">
                    <Text fontSize="lg" fontWeight="bold">
                        {t("claimsInvoicesOverview:branchCompanyModal.title")}
                    </Text>
                </ModalHeader>
                <ModalCloseButton id="branchCompanyModalClose" />
                <ModalBody>
                    {isLoading && (
                        <Stack>
                            <Skeleton height="20px" />
                            <Skeleton height="20px" />
                            <Skeleton height="20px" />
                        </Stack>
                    )}
                    {isError && (
                        <Center>
                            <Text>
                                {t("claimsInvoicesOverview:branchCompanyModal.noData")}
                            </Text>
                        </Center>
                    )}
                    <Flex
                        flexDir="column"
                        gap="0.5rem"
                        maxH="50rem"
                        overflowY="auto"
                        pr="0.5rem"
                    >
                        {branchCompanies?.map((company, index) => (
                            <Box key={company.crmId} w="100%">
                                <Flex
                                    justifyContent="space-between"
                                    w="100%"
                                    alignItems="center"
                                >
                                    <Box>
                                        <Text
                                            maxW="15rem"
                                            whiteSpace="nowrap"
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                        >
                                            <TruncatedText text={company.companyName} />
                                        </Text>
                                        {selectedBranch?.crmId === company.crmId && (
                                            <Tag
                                                size="sm"
                                                variant="solid"
                                                colorScheme="blue"
                                            >
                                                {t(
                                                    "claimsInvoicesOverview:branchCompanyModal.selected"
                                                )}
                                            </Tag>
                                        )}
                                    </Box>
                                    <IconButton
                                        data-testid="selectBtn"
                                        size="sm"
                                        variant="primary"
                                        icon={<ArrowRightIcon size="sm" />}
                                        aria-label="Select company"
                                        mb="0.5rem"
                                        onClick={() => handleSelectBranch(company)}
                                    />
                                </Flex>
                                {branchCompanies.length - 1 > index && <Divider />}
                            </Box>
                        ))}
                    </Flex>
                    <Flex flexDir="column" gap="1rem" mt="1rem">
                        <Button data-testid="cancelBtn" w="100%" variant="ghost" onClick={onClose}>
                            {t("main:cancel")}
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
