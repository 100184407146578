import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightAddon } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";


type FormNumberInputProps = {
    label: string;
    id: string;
    isRequired: boolean;
    errorMessage: string | undefined;
    control: any;
    currency: string | undefined;
    allowNegative?: boolean;
};

export const FormNumberInput = ({
    label,
    id,
    errorMessage,
    isRequired,
    control,
    currency,
    allowNegative = true
}: FormNumberInputProps) => {
    return (
        <FormControl isInvalid={!!errorMessage} isRequired={isRequired} maxW="15rem">
            <FormLabel>
                {label}
            </FormLabel>
            <Controller
                control={control}
                name={id}
                render={({ field }) => (
                    <InputGroup>
                        <Input
                            id={id}
                            {...field}
                            as={NumericFormat}
                            thousandSeparator=","
                            decimalSeparator="."
                            allowNegative={allowNegative}
                            decimalScale={2}
                            ref={null}
                            autoComplete="off"
                            value={field.value || ''}
                            data-lpignore="true"
                        />
                        <InputRightAddon
                            p="0 1rem"
                            bg="anchor.navy.50"
                        >{currency}</InputRightAddon>
                    </InputGroup>
                )}
            />
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
    );
};
